.myths{
  @extend %page;
//  background: $color-paper;
}

.myths__wrapper{
  height: 100%;
  width: 100%;
  overflow: scroll;

}

.myths__titles{
  // top: 0;
  // width: 100%;
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  margin-left: 5rem;
  margin-top: 10%;
  margin-right: 5rem;
  position: absolute;
  width: -webkit-fill-available;
}

.myths__titles__label{
  margin-top: 5rem;
  height: 2rem;

  @extend %description-12-bold;
}
.myths__titles__title{
  @extend %title-160;
  height: 20rem;
  border-bottom: 1px solid $color-red;

  span {
    transform: translateY(4rem);
  }

}


.myths__gallery__wrapper{
  // margin-top: 10%;
  // width: 100%;
  // height: 100%;
  // top: 0;
  //
  // &:last-child{
  //   margin-bottom: 25rem;
  // }

}

.myths__gallery__link{
  pointer-events: cursor;
  display: flex;
  flex-direction: column;
  &:last-child{
    margin-bottom: 10rem;
  }

}
.myths__gallery__media{
  // width: 100vw;
  // display: flex;
  @include media('<phone') {

    margin-bottom: 5rem;
  }
}

.myths__gallery__media__image{
  height: 27rem;
  top: 0;
  width: 100%;
  object-fit: cover;
  // margin-left: 5rem;
  // margin-right: 5rem;
  opacity: 1;

  filter:  grayscale(1) contrast(0.95) url(#gamma-red);
  transition: 0.3s ease;
  &:hover {
    opacity: 1;
    filter: none;
  }

}

.myths__content{
  height: 100vh;
  width: 100vw;
}

.myths__article{

}
.myths__article__title{

}
.myths__article__description{

}
