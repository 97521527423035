.detail{
  //@extend %center;
  //@extend %page;
//  background: $color-paper;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}
.detail__wrapper{
  @extend %center;
  width: 100%;
//  overflow: hidden;
  display:block;

  @include media('<phone') {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.detail__media{
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  @include media('<phone') {
    object-fit: contain;
    width: 100%;
    height: 70rem;
    overflow-y: scroll;
    overflow-x: hidden;

  }
}
.detail__media__image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  justify-content: center;
}

.detail__information{
//  position: relative;

}

//little title on page
.detail__information__myth{
  @extend %title-160;
  //@extend %cover;
  position: fixed;
  //margin-bottom: 10rem;
  //margin-top: auto;
  top: 17rem;
  left: 3rem;
  text-align: border-bottom;
  transform: translateX(-20rem) translateY(27rem) rotate(-90deg);
  color: $color-red;
  @include media('<phone') {
    display: none;
  }
}

//this shows title- if same as myth title it will appear double(most likeley!)
.detail__information__title{
  @extend %title-200;
  position: absolute;
  width: 50%;
  text-align: right;
  padding-right: 10rem;
  padding-top: 4rem;
//  transform: rotate(-90deg);
  @include media('<phone') {
    position: relative;
    text-align: left;
    margin-left: 5rem;
  }
}



.detail__information__content{
  margin-left: 50%;
  padding-top: 3rem;
  transform: translateX(-1.5rem);
  @include media('<phone') {
    display: flex;
    flex-direction: column;
    margin-left: 10rem;
  }
}
.detail__information__highlights{
  margin-top: 4rem;

}

.detail__information__highlight{
  align-items: center;
  display: flex;

  &:not(:first-child) {
    margin-top:4rem;
  }
}
.detail__information__highlight__icon{
  border: 1px solid #ff0000;
  border-radius: 50%;
  margin-right: 4rem;
  margin-left: 1.5rem;
  height: 4rem;
  width: 4rem;
}

.detail__information__highlight__text{

}

.detail__information__list{
  margin-top: 5rem;
}

.detail__information__item{

  display: flex;

  &:not(:first-child) {
    margin-top:4rem;
  }
}
.detail__information__item__title{
  @extend %description-12-bold-slanted;
  width: 10rem;
  padding-right: 4rem;
}
.detail__information__item__description{
  max-width: 44rem;
  border-bottom: 1px solid currentColor;
  padding-bottom: 4rem;
}
.line{
  width: 112px;
  height: 47px;
  border-bottom: 1px solid currentColor;
  position: absolute;

}

.detail__information__link{
  @extend %description-12-bold-slanted;
  display: inline-block;
  margin-top: 3rem;
  margin-bottom: 25rem;
  @include media('<phone') {

    margin-bottom: 10rem;
  }
}

.detail__button{
  //@extend %cover;
  position: absolute;
  @extend %description-16-bold;
  right: 5rem;

  padding: 1rem;

  z-index: 10;
  margin-top: 18rem;
  @include media('<phone') {
    position: relative;
    margin-bottom: 4rem;
    margin-top: 10rem;
    right: 0;
  }
}

.detail__button__icon{
  position: absolute;
  //width: 25rem;
  // bottom: 2rem;
  // left:50%;
  // top:0;
  width: 10rem;
  height: 10rem;
  transform: translateY(-40%) translateX(-80%);
  @include media('<phone') {

    width: 18rem;
    height: 14rem;
    transform: translateY(-30%) translateX(-80%);
  }
}
