.about__what__wrapper{

  display: flex;
  justify-content: center;
  margin: auto;
  // margin-top: 10rem;
}
.about__what__label{
  @extend %description-12-bold;
  margin-right: 4rem;
}

.about__what__text{
  @extend %description-16;
  line-height: 1.3;
  //word-wrap: normal;
  width: 45rem;

}

.about__what__media{
  margin-left: 15rem;
  margin-right: 15rem;

}
.about__what__media__image{
  // @extend %center;
  //
  // width: 80rem;
  // margin-top: 20%;
  // height: auto;
  // margin-left: 16rem;
  // object-fit: contain;
  max-height: 80rem;
}
