*,
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}

html {
  font-size: calc(100vw / 1920 *10);
  line-height: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;

  color: $color-red;
  background: $color-paper;

  overflow: hidden;


  @include media('<phone') {
    font-size:calc(100vw / 750 *10);
  }

}

body {
  font-family: $font-object-sans;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  @include media('<phone') {
    font-family: $font-object-sans;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
  }

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: z('canvas');
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;

}
//
img {
  opacity: 0;
  transition: opacity 0.5s ease;

//  vertical-align: middle;
  &.loaded {
    opacity: 1;
  }
}

.content{
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

[data-animation="marquee"] {
  opacity: 1;
  visibility: visible;
}

[data-animation="highlight"] {
  display: flex;

}

[data-animation="label"] {
  span {
    display: inline-block; //this one is important for having animations pop per line

    vertical-align: top;
//    padding-bottom: 2.5rem;
  }
}
[data-animation="paragraph"],
[data-animation="title"] {
  span {
    display: inline-block; //this one is important for having animations pop per line
    overflow: hidden;
    vertical-align: top;
//    padding-bottom: 2.5rem;
  }
}

[data-animation="title2"] {
  span {
    display: inline-block; //this one is important for having animations pop per line
    overflow: hidden;
    vertical-align: top;
    padding-bottom: 3rem;
  }
}
[data-animation="link"] {
  display: inline-block;
  position: relative;
  white-space: nowrap;

  span {
    display: inline-block;
    transform-origin: center center 0.5em;
    vertical-align: middle;
  }
}
