@font-face {
    font-family: $font-voyage;
    src: url('../fonts/voyage-regular.woff2') format('woff2'),
        url('../fonts/voyage-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-voyage;
    src: url('../fonts/voyage-bold.woff2') format('woff2'),
        url('../fonts/voyage-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-object-sans;
    src: url('../fonts/object-sans-bold-slanted.woff2') format('woff2'),
        url('../fonts/object-sans-bold-slanted.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: $font-object-sans;
    src: url('../fonts/object-sans-bold.woff2') format('woff2'),
        url('../fonts/object-sans-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-object-sans;
    src: url('../fonts/object-sans-heavy.woff2') format('woff2'),
        url('../fonts/object-sans-heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


// @font-face {
//     font-family: $font-object-sans;
//     src: url('../fonts/object-sans-heavy-slanted.woff2') format('woff2'),
//         url('../fonts/object-sans-heavy-slanted.woff') format('woff');
//     font-weight: 800;
//     font-style: italic;
//     font-display: swap;
// }

@font-face {
    font-family: $font-object-sans;
    src: url('../fonts/object-sans-regular.woff2') format('woff2'),
        url('../fonts/object-sans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-object-sans;
    src: url('../fonts/object-sans-slanted.woff2') format('woff2'),
        url('../fonts/object-sans-slanted.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

// @font-face {
//     font-family: $font-object-sans;
//     src: url('../fonts/object-sans-slanted.woff2') format('woff2'),
//         url('../fonts/object-sans-slanted.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }
