%page {
  @extend %cover;
   //
  opacity: 0;
  visibility: hidden;
  z-index: z('content');
}

// %page--active {
//   opacity: 1;
//   visibility: visible;
// }

%page__wrapper {
  color: inherit;
}
