.home{
  @extend %center;
  @extend %page;

  overflow: hidden;
  text-align: center;
  object-fit: cover;

  // &--active {
  //   @extend %page--active;
  // }
}

.home__wrapper{
  @extend %cover;
//  width: 100%;
//  height: 100%;
  &:before,
  &:after {
    content: '';
    height: 35rem;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

}

.home__gallery{
  display: flex;
  flex-wrap: wrap;
  left: 50%;
  //@extend %cover;
  padding: 0 2.5rem;
  //position: absolute;
  top: 0;
  width: 246.2rem;
  height: 164.8rem;
  visibility: hidden;
  object-fit: cover;
  overflow: visible;

}

.home__gallery__media{
//  position: absolute;

  &:nth-child(1) {
    width: 31.4rem;
    height: 22.6rem;
    top: 22.2rem;
    left: 3.6rem;
  }

  &:nth-child(2) {
    width: 62rem;
    height: 40.3rem;
    top: 13.3rem;
    left: 40.2rem;
  }

  &:nth-child(3) {
    width: 32.3rem;
    height: 48.4rem;
    top: 0.0rem;
    left: 109.3rem;
  }

  &:nth-child(4) {
    width: 30.6rem;
    height: 22.0rem;
    top: 14.7rem;
    left: 144.3rem;
  }

  &:nth-child(5) {
    width: 66.2rem;
    height: 47.8rem;
    top: 0.0rem;
    left: 180.1rem;
  }

  &:nth-child(6) {
    width: 65rem;
    height: 46.8rem;
    top: 64.6rem;
    left: 0.0rem;
  }

  &:nth-child(7) {
    width: 65.7rem;
    height: 36.8rem;
    top: 59.1rem;
    left: 68.2rem;
  }

  &:nth-child(8) {
    width: 58.7rem;
    height: 42.3rem;
    top: 53.6rem;
    left: 139.1rem;
  }

  &:nth-child(9) {
    width: 42.8rem;
    height: 57.0rem;
    top: 53.6rem;
    left: 200.6rem;
  }

  &:nth-child(10) {
    width: 41.9rem;
    height: 31.1rem;
    top: 126.2rem;
    left: 1.5rem;
  }

  &:nth-child(11) {
    width: 42.9rem;
    height: 30.9rem;
    top: 116.9rem;
    left: 47.8rem;
  }

  &:nth-child(12) {
    width: 42rem;
    height: 62rem;
    top: 101.4rem;
    left: 95.1rem;
  }

  &:nth-child(13) {
    width: 22.9rem;
    height: 46.0rem;
    top: 101.4rem;
    left: 145.5rem;
  }

  &:nth-child(14) {
    width: 71.3rem;
    height: 45.4rem;
    top: 116.9rem;
    left: 174.9rem;
  }
}
.home__gallery__media__image{
  display: block;
//  width: 100%;
  opacity: 1;
}
.home__button{
  @extend %description-16-bold;
  // align-items: center;
  bottom: 5rem;
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
  left: 50%;
  align-items: center;

  // display: flex;
  // margin-left: 0;
  // padding: 1rem;
  // left: 50%;
  // letter-spacing: 0.05rem;
  // //position: absolute;
  transform: translateX(-50%);
  @include media('<phone') {
    bottom: 10rem;
    width: 40rem;
  }
  // justify-content: center;
  // margin-bottom: 15rem;

  .home__button__icon{
    display: block;
    bottom: -2.5rem;
    width: 27rem;
    position: absolute;
    margin-left: -6rem;
    @include media('<phone') {
      bottom: -2.5rem;
      width: 40rem;
    }
  }
}
