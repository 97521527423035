.preloader{
  @extend %center;
  @extend %cover;
  position: absolute;
  align-items: center;
  background-color: $color-red;
  color: $color-pink;
  width: 100vw;
  height: 100vh;
//  display: none;
  // justify-content: center;
  text-align: center;
  // width: 100vw;
  // height: 100vh;
  margin: 0px auto;
  z-index: z('preloader');
}
.preloader__text{

  @extend %cover;
  font-size: 0;
  transform: translateY(50%);
  width: 30%;
  float: left;
  margin-left: 15%;

  span {
    display: inline-block;
    overflow: hidden;
  }

  span span {
    @extend %description-12-bold-slanted;
  }
  @include media('<phone') {
    margin-left: 5rem;
  }
}
.preloader__number{
  @extend %title-600;
  overflow: hidden;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  text-align: right;

}

.preloader__number__text{
  display: inline-block;
}
