%description-10 {
  font-family: $font-object-sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  @include media('<phone') {
    font-family: $font-object-sans;
    font-size: 1.3rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1.5;
  }
}

%description-12 {
  font-family: $font-object-sans;
  font-size: 1.2rem;
  font-style: normal;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1.5;
  @include media('<phone') {
    font-family: $font-object-sans;
    font-size: 1.6rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1.5;
  }
}

%description-12-slanted {
  font-family: $font-object-sans;
  font-size: 1.2rem;
  font-style: italic;
  text-transform: uppercase;
  font-weight: normal;
}

%description-12-bold {
  font-family: $font-object-sans;
  font-size: 1.2rem;
  font-style: normal;
  text-transform: uppercase;
  font-weight: bold;
  @include media('<phone') {
    font-family: $font-object-sans;
    font-size: 2.2rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
  }
}

%description-12-bold-slanted {
  font-family: $font-object-sans;
  font-size: 1.2rem;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
}

%description-16 {
  font-family: $font-object-sans;
  font-size: 1.6rem;
  font-style: normal;
  text-transform: uppercase;
  font-weight: normal;
  @include media('<phone') {
    font-family: $font-object-sans;
    font-size: 1.8rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: normal;
  }
}

%description-16-bold {
  font-family: $font-object-sans;
  font-size: 1.6rem;
  font-style: normal;
  text-transform: uppercase;
  font-weight: bold;
  @include media('<phone') {
    font-family: $font-object-sans;
    font-size: 3.2rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
  }
}

%description-16-bold-slanted {
  font-family: $font-object-sans;
  font-size: 1.6rem;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
  @include media('<phone') {
    font-family: $font-object-sans;
    font-size: 1.8rem;
    font-style: italic;
    text-transform: uppercase;
    font-weight: bold;
  }
}

%description-35-bold-slanted {
  font-family: $font-object-sans;
  font-size: 3.5rem;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
}
