.about__highlight{
  display: flex;
  flex-direction: column;
  margin-bottom: 20%;
  justify-content: flex-end;
  text-align: center;
  @include media('<phone') {
    margin-bottom: 70%;
  }
}
.about__highlight__wrapper{
  width: 40rem;

}

.about__highlight__label{

}

.about__highlight__highlight{
  @extend %description-35-bold-slanted;


}
