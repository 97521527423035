.detail__information__gallery {
  height: 58.6rem;
  margin: 0;
  position: relative;
  pointer-events: none;
  visibility: hidden;

}

.detail__information__gallery__wrapper {
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

}

.detail__information__gallery__media {
  height: 43.5rem;
  margin: 0 4rem;
  position: relative;
  width: 30.9rem;
  object-fit: cover;
  @include media('<phone') {
    justify-content: center;
    align-items: center;
    width: 32rem;
  }
}

.detail__information__gallery__media__image {
  @extend %cover;
  object-fit: cover;
}
