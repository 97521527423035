.about__content{
  //margin: auto;
}
.about__content__wrapper{
  margin-right: 30rem;
  display: inline-block;
  flex-direction: column;
  position: relative;
  //height: 100vh;
  top: 45%;
}

//The big header
.about__content__label{
  @extend %title-200;

}

//The line
.about__content__line{
  margin-top: 5rem;
  border-top: 1px solid $color-red;
  width: 150px;
  align-items: flex-start;
  display: flex;
}

//The text
.about__content__description_how{
  @extend %description-16;
  line-height: 1.3;
  margin-top: 5rem;
  width: 45rem;

  li {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;

  }
}

//The image
.about__content__media{
  position: absolute;
  bottom: 80%;
  margin-left: 40%;
  z-index: -1;

}


.about__content__media__image{

}
