
.about__gallery{
  @extend %center;
  height: 100vh;
  position: relative;

}
.about__gallery__wrapper{
  display: grid;
  grid-template-columns: 40rem 40rem 40rem 40rem;
  grid-template-rows: 30rem 30rem 30rem;
  justify-content: center;
  object-fit: contain;
//  margin-right: 15rem;
  @include media('<phone') {
    display: grid;
    grid-template-columns: 25rem 25rem 25rem;
    grid-template-rows: 20rem 20rem 20rem 20rem;
  }
}

.about__gallery__media{
  @extend %center;
  top: 50%;
  left: 50%;
}
.about__gallery__media__image{
  max-height: 12rem;
  max-width: 12rem;
  object-fit: cover;
  @include media('<phone') {
    max-height: 15rem;
    max-width: 15rem;
    object-fit: cover;
  }
}

.about__gallery__icon {
  position: absolute;
  width: 19rem;
  display: flex;
  top: 60%;
  right: 0;
  @include media('<phone') {
    right: 37rem;
    top: 65%;
  }
}
