.navigation{
  @extend %cover;
  position: fixed;
  pointer-events: none;
  z-index: z('navigation');
}
.navigation__wrapper{
  overflow: hidden;

}
.navigation__link{
  font-size: 0;
  height: 2.5rem;
  margin: 0 auto;
  position: fixed;
  pointer-events: auto;
  top: 3rem;
  width: 4rem;
  left: 50%;
  transition: opacity 0.4s ease;
  &:hover {
    opacity: 0.5;
  }

  @include media('<phone') {
    height: 5rem;
    width: 5rem;
    left: 4rem;
  }
}


.navigation__link__icon{
  display: block;
}

//css menu
.menu__wrapper {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.menu__icon {
  //outline: 1px solid red;
  @include media('<phone') {
    position: fixed;
    top: 3rem;
    justify-content: center;
    right: 23rem;
    cursor: pointer;
    pointer-events: auto;
    z-index: 999;
  }
}

.menu__button {
  display: none;
  @include media('<phone') {
    clear:both;
    position: fixed;
    max-height: 0;
    transition: max-height .4s ease-out;
    display: none;
    cursor: pointer;
    background: none;
    transition: background .2s ease-out;
  }
}

.menu__button__checked {
  max-height: 100%;
  background: $color-paper;
  width: 100%;
  left: 0;
  top: 0;

}

.nav__icon__hide {
  background: none;
}

/*
.menu__button:checked ~ .navigation__list{
  max-height: 100%;
  background: $color-paper;
  width: 100%;
  left: 0;
  top: 0;
}
*/
.menu__button:checked ~ .nav__icon {
  background: none;
}
.menu__button:checked ~ .nav__icon::before {
  transform: rotate(-45deg);
  top:0;
}
.menu__button:checked ~ .nav__icon::after {
  transform: rotate(45deg);
  top:0;
}


//hamburger menu

.nav__icon {
  background: $color-red;
  top: 3rem;
  z-index: 10;
  height: 3px;
  width: 25px;
  position: relative;
  display: flex;
  cursor: pointer;
  pointer-events: auto;
  transition: background .2s ease-out;
  @include media('>phone') {
    display: none;
  }
}
.nav__icon::before {
  background: $color-red;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 7px;
  transition: all .2s ease-out;
}
.nav__icon::after {
  background: $color-red;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: -7px;
  position: relative;
  transition: all .2s ease-out;
}


//links top other opages
.navigation__list{
  display: flex;
  //justify-content: flex-start;
  height: 10rem;
  flex-direction: row;
  align-items: center;

  @include media('<phone') {
    visibility: hidden;
    position: fixed;
    background: $color-paper;
  //
    // clip-path: circle(100px at 90% -10%);
    // -webkit-clip-path: circle(100px at 90% -10%);
    transition: all 1s ease-out;
    height: 0vh;
    opacity: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    // .navigation__list.open {
    //   clip-path: circle(1000px at 90% -10%);
    //   -webkit-clip-path: circle(1000px at 90% -10%);
    // }
  }
}

.navigation__list__show {
  @include media('<phone') {
    visibility: visible;
    height: 100vh;
    transition: height 1s ease;
    opacity: 1;
    transition: opacity 0.4 ease;
  }
}

.navigation__line {
  position: absolute;
  transform: scaleX(-1) rotate(-90deg);
  left: 5rem;
  top: 4rem;
  height: 8rem;
  cursor: pointer;
  display: none;
  z-index: 10;
  @include media('<phone') {
    display: none;
  }
}

.navigation__list__item{
  left: 4rem;
  top: 3.5rem;
  margin-right: 4rem;
}
.navigation__list__link{
  @extend %title-heavy;
  color: $color-grey;
  padding: .7rem 1.5rem;
  border-radius: 3rem;

  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
  display: block;
  &:first-child {
    margin-left: 5rem;
  }
}

.navigation__button__wrapper {
  position: fixed;
  top: 4rem;
  right: 5rem;
  @include media('<phone') {
    top: 3.5rem;
    right: -3.5rem;
  }
}
.navigation__button__link {
  @extend %title-heavy;
  color: $color-pink;
  background: $color-red;

  padding: .7rem 1.5rem;

  border-radius: 3rem;
  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
  display: flex;
  &:hover {
    transform: scale(1.3) 0.4s ease;
  }
  @include media('<phone') {
    padding-right: 7rem;
  }
}
