.about{
//  @extend %cover;
// //  @extend %center;
//  overflow: hidden;
  @extend %page;
//  background: $color-paper;


  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
  //position: absolute;


//This is for javascript transitions:

  opacity: 0;

//This is to hide the scroll-bar
  scrollbar-width: none;
  -ms-overflow-style:none;
}

::-webkit-scrollbar {
  display: none;
}


//This is to flip the canvas again to have horizontal flex-direction

.about__wrapper{
  height: 100vh;
  width: 100vw;
  display:flex;
  flex-direction: row;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;


//  float: left;
//  vertical-align: top;
}

.about__inner__wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  display:flex;
  flex-direction: row;
}

//Big grey BG text full-width
.about__titles{
  @extend %title-full;
  white-space:nowrap;
  position: absolute;
  color: $color-emboss;
  z-index: -10;
  line-height: .55;
  opacity: .8;
  top: 0;
  left: 0;

  @include media('<phone') {
  line-height: .80;
  }
}

.about__line__drawing {
  position: absolute;
  width: 1000rem;
  display: flex;
  overflow: hidden;
  transform: translateY(-160rem);
  z-index: 10;
}

//Secondary tiles
.about__title__wrapper{
  height: 100vh;
//  bottom: 10%;
  position: relative;
  display: inline;
  align-self: center;
  margin-left: 4rem;
  margin-right: 100rem;

}

.about__title__label{
  @extend %description-12-bold;
  margin-top: 12rem;
  margin-left: 6rem;

}

.about__question__wrapper {
  position: absolute;
  margin-left: 6rem;
  white-space: nowrap;
  padding: 0;
  pointer-events: none;
//  bottom: 60rem;
  transform: translateX(-50%) rotate(-90deg);
  will-change: transform;
}

.about__title__murtaza__question{
  @extend %title-160;
  display: inline-block;
  // flex-direction: row;
  text-align: left;
  visibility: visible !important;
  opacity: 1 !important;
  margin-left: 40rem;

}

// About button to lead to next page
.about__button__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  position: relative;
}
.about__button{
//  @extend %link--hidden;
  @extend %description-16-bold;
  width: 15rem;
//  line-height: 1;
  margin-bottom: 10rem;
  pointer-events: auto;
  transition: opacity 0.4s ease;

  @include media('<phone') {
    position: absolute;
    right: 29rem;
  }
  .about__button__icon{
    position: absolute;
    width: 15rem;
    display: block;
    justify-content: center;
    bottom: 7rem;
    @include media('<phone') {
      transform: translateY(6rem);
      bottom: 5rem;
      width: 18rem;
    }
  }
}
