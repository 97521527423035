.journal{
  @extend %page;

//  background: $color-paper;
}
.journal__wrapper{
  @extend %center;
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: block;
}

.journal__title{
  @extend %title-200;
  align-items: margin-right;
  justify-content: right;
  text-align: right;
  padding-top: 40rem;
  padding-right: 5rem;
//  margin-bottom: 4rem;
  border-bottom: 1px solid $color-red;
//  display: flex;
}

.journal__posts{
  @extend %description-16;
  display: flex;
  flex-direction: column;
//  justify-content: flex-start;
  margin-left: 25%;
  @include media('<phone') {
    margin-left: 5rem;

  }
}

.journal__posts__wrapper{
//  width: 60rem;
  position: relative;
  margin-top: 5rem;
  display: inline-block;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 50rem;
  margin-right: 5rem;

}

.journal__posts__row{
  float: left;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

//  justify-content: left;
  border-bottom: 1px solid $color-red;

  &:first-child {
    border-top: 1px solid $color-red;
    margin-top:2rem;

  }

}


.journal__posts__date{
  @extend %description-12;
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: inline-block;
  // &:first-child {
  //   border-top: 1px solid $color-red;
  //   margin-top:2rem;
  //
  // }
  vertical-align: middle;

}

.journal__posts__link{
  position: absolute;
  text-align: left;
  margin-left: 25%;
  padding: 1rem;
//  padding-top: 1rem;

//   &:not(:first-child) {
//     margin-top:2rem;
// //    position: absolute;
//   }

}

.journal__posts__media{

}
.journal__posts__media{

}
.journal__posts__media__image{

}
