html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.post__titles__title2, .home__wrapper, .detail__information__gallery__media__image, .detail__information__artwork__media__image, .post, .myths, .journal, .home, .about, .navigation, .preloader__text, .preloader {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.journal__wrapper, .home, .detail__wrapper, .about__gallery__media, .about__gallery, .preloader {
  align-items: center;
  display: flex;
  justify-content: center;
}

.dg.ac {
  z-index: 99999 !important;
}

@font-face {
  font-family: "Voyage";
  src: url("../fonts/voyage-regular.woff2") format("woff2"), url("../fonts/voyage-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Voyage";
  src: url("../fonts/voyage-bold.woff2") format("woff2"), url("../fonts/voyage-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Object Sans";
  src: url("../fonts/object-sans-bold-slanted.woff2") format("woff2"), url("../fonts/object-sans-bold-slanted.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Object Sans";
  src: url("../fonts/object-sans-bold.woff2") format("woff2"), url("../fonts/object-sans-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Object Sans";
  src: url("../fonts/object-sans-heavy.woff2") format("woff2"), url("../fonts/object-sans-heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Object Sans";
  src: url("../fonts/object-sans-regular.woff2") format("woff2"), url("../fonts/object-sans-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Object Sans";
  src: url("../fonts/object-sans-slanted.woff2") format("woff2"), url("../fonts/object-sans-slanted.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
*,
*:after,
*:before {
  box-sizing: border-box;
  user-select: none;
}

html {
  font-size: 0.5208333333vw;
  line-height: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  color: #ff0000;
  background: #f6eee3;
  overflow: hidden;
}
@media (max-width: 767px) {
  html {
    font-size: 1.3333333333vw;
  }
}

body {
  font-family: "Object Sans";
  font-size: 1.2rem;
  font-style: normal;
  font-weight: normal;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}
@media (max-width: 767px) {
  body {
    font-family: "Object Sans";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
  }
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

canvas {
  pointer-events: none;
  position: relative;
  z-index: 1;
}

button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  outline: none;
}

img {
  opacity: 0;
  transition: opacity 0.5s ease;
}
img.loaded {
  opacity: 1;
}

.content {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

[data-animation=marquee] {
  opacity: 1;
  visibility: visible;
}

[data-animation=highlight] {
  display: flex;
}

[data-animation=label] span {
  display: inline-block;
  vertical-align: top;
}

[data-animation=paragraph] span,
[data-animation=title] span {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

[data-animation=title2] span {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  padding-bottom: 3rem;
}

[data-animation=link] {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
[data-animation=link] span {
  display: inline-block;
  transform-origin: center center 0.5em;
  vertical-align: middle;
}

.preloader {
  position: absolute;
  align-items: center;
  background-color: #ff0000;
  color: #edd8d8;
  width: 100vw;
  height: 100vh;
  text-align: center;
  margin: 0px auto;
  z-index: 5;
}

.preloader__text {
  font-size: 0;
  transform: translateY(50%);
  width: 30%;
  float: left;
  margin-left: 15%;
}
.preloader__text span {
  display: inline-block;
  overflow: hidden;
}
@media (max-width: 767px) {
  .preloader__text {
    margin-left: 5rem;
  }
}

.preloader__number {
  overflow: hidden;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  text-align: right;
}

.preloader__number__text {
  display: inline-block;
}

.navigation {
  position: fixed;
  pointer-events: none;
  z-index: 3;
}

.navigation__wrapper {
  overflow: hidden;
}

.navigation__link {
  font-size: 0;
  height: 2.5rem;
  margin: 0 auto;
  position: fixed;
  pointer-events: auto;
  top: 3rem;
  width: 4rem;
  left: 50%;
  transition: opacity 0.4s ease;
}
.navigation__link:hover {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .navigation__link {
    height: 5rem;
    width: 5rem;
    left: 4rem;
  }
}

.navigation__link__icon {
  display: block;
}

.menu__wrapper {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media (max-width: 767px) {
  .menu__icon {
    position: fixed;
    top: 3rem;
    justify-content: center;
    right: 23rem;
    cursor: pointer;
    pointer-events: auto;
    z-index: 999;
  }
}

.menu__button {
  display: none;
}
@media (max-width: 767px) {
  .menu__button {
    clear: both;
    position: fixed;
    max-height: 0;
    transition: max-height 0.4s ease-out;
    display: none;
    cursor: pointer;
    background: none;
    transition: background 0.2s ease-out;
  }
}

.menu__button__checked {
  max-height: 100%;
  background: #f6eee3;
  width: 100%;
  left: 0;
  top: 0;
}

.nav__icon__hide {
  background: none;
}

/*
.menu__button:checked ~ .navigation__list{
  max-height: 100%;
  background: $color-paper;
  width: 100%;
  left: 0;
  top: 0;
}
*/
.menu__button:checked ~ .nav__icon {
  background: none;
}

.menu__button:checked ~ .nav__icon::before {
  transform: rotate(-45deg);
  top: 0;
}

.menu__button:checked ~ .nav__icon::after {
  transform: rotate(45deg);
  top: 0;
}

.nav__icon {
  background: #ff0000;
  top: 3rem;
  z-index: 10;
  height: 3px;
  width: 25px;
  position: relative;
  display: flex;
  cursor: pointer;
  pointer-events: auto;
  transition: background 0.2s ease-out;
}
@media (min-width: 769px) {
  .nav__icon {
    display: none;
  }
}

.nav__icon::before {
  background: #ff0000;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 7px;
  transition: all 0.2s ease-out;
}

.nav__icon::after {
  background: #ff0000;
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: -7px;
  position: relative;
  transition: all 0.2s ease-out;
}

.navigation__list {
  display: flex;
  height: 10rem;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 767px) {
  .navigation__list {
    visibility: hidden;
    position: fixed;
    background: #f6eee3;
    transition: all 1s ease-out;
    height: 0vh;
    opacity: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .navigation__list__show {
    visibility: visible;
    height: 100vh;
    transition: height 1s ease;
    opacity: 1;
    transition: opacity 0.4 ease;
  }
}

.navigation__line {
  position: absolute;
  transform: scaleX(-1) rotate(-90deg);
  left: 5rem;
  top: 4rem;
  height: 8rem;
  cursor: pointer;
  display: none;
  z-index: 10;
}
@media (max-width: 767px) {
  .navigation__line {
    display: none;
  }
}

.navigation__list__item {
  left: 4rem;
  top: 3.5rem;
  margin-right: 4rem;
}

.navigation__list__link {
  color: #cacaca;
  padding: 0.7rem 1.5rem;
  border-radius: 3rem;
  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
  display: block;
}
.navigation__list__link:first-child {
  margin-left: 5rem;
}

.navigation__button__wrapper {
  position: fixed;
  top: 4rem;
  right: 5rem;
}
@media (max-width: 767px) {
  .navigation__button__wrapper {
    top: 3.5rem;
    right: -3.5rem;
  }
}

.navigation__button__link {
  color: #edd8d8;
  background: #ff0000;
  padding: 0.7rem 1.5rem;
  border-radius: 3rem;
  justify-content: center;
  pointer-events: auto;
  cursor: pointer;
  display: flex;
}
.navigation__button__link:hover {
  transform: scale(1.3) 0.4s ease;
}
@media (max-width: 767px) {
  .navigation__button__link {
    padding-right: 7rem;
  }
}

.detail__information__artwork__description {
  font-family: "Object Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .detail__information__artwork__description {
    font-family: "Object Sans";
    font-size: 1.3rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1.5;
  }
}

.journal__posts__date {
  font-family: "Object Sans";
  font-size: 1.2rem;
  font-style: normal;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .journal__posts__date {
    font-family: "Object Sans";
    font-size: 1.6rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 1.5;
  }
}

.myths__titles__label, .about__what__label, .about__title__label {
  font-family: "Object Sans";
  font-size: 1.2rem;
  font-style: normal;
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 767px) {
  .myths__titles__label, .about__what__label, .about__title__label {
    font-family: "Object Sans";
    font-size: 2.2rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.post__information__link, .detail__information__link, .detail__information__item__title, .preloader__text span span {
  font-family: "Object Sans";
  font-size: 1.2rem;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
}

.journal__posts, .about__what__text, .about__content__description_how {
  font-family: "Object Sans";
  font-size: 1.6rem;
  font-style: normal;
  text-transform: uppercase;
  font-weight: normal;
}
@media (max-width: 767px) {
  .journal__posts, .about__what__text, .about__content__description_how {
    font-family: "Object Sans";
    font-size: 1.8rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: normal;
  }
}

.post__text__text p, .home__button, .detail__button, .about__button {
  font-family: "Object Sans";
  font-size: 1.6rem;
  font-style: normal;
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 767px) {
  .post__text__text p, .home__button, .detail__button, .about__button {
    font-family: "Object Sans";
    font-size: 3.2rem;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.post__titles__title, .about__highlight__highlight {
  font-family: "Object Sans";
  font-size: 3.5rem;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
}

.detail__information__artwork__title {
  font-family: "Voyage";
  font-size: 3.5rem;
  font-weight: normal;
}
@media (max-width: 767px) {
  .detail__information__artwork__title {
    font-family: "Voyage";
    font-size: 7rem;
    font-weight: normal;
  }
}

.post__title, .myths__titles__title, .detail__information__myth, .about__title__murtaza__question {
  font-family: "Voyage";
  font-size: 16rem;
  font-weight: normal;
}
@media (max-width: 767px) {
  .post__title, .myths__titles__title, .detail__information__myth, .about__title__murtaza__question {
    font-family: "Voyage";
    font-size: 14rem;
    font-weight: normal;
    white-space: nowrap;
  }
}

.post__titles__title2, .journal__title, .detail__information__title, .about__content__label {
  font-family: "Voyage";
  font-size: 20rem;
  font-weight: normal;
  line-height: 0.8;
}

.preloader__number {
  font-family: "Voyage";
  font-size: 60rem;
  font-weight: normal;
}
@media (max-width: 767px) {
  .preloader__number {
    font-family: "Voyage";
    font-size: 27rem;
    font-weight: normal;
  }
}

.about__titles {
  font-family: "Voyage";
  font-size: 180rem;
  font-weight: normal;
}

.navigation__list__link, .navigation__button__link {
  font-family: "Object Sans";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .navigation__list__link, .navigation__button__link {
    font-size: 2.8rem;
  }
}

.post, .myths, .journal, .home, .about {
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}

.about {
  width: 100vh;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.about__wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
}

.about__inner__wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.about__titles {
  white-space: nowrap;
  position: absolute;
  color: #e6dfd7;
  z-index: -10;
  line-height: 0.55;
  opacity: 0.8;
  top: 0;
  left: 0;
}
@media (max-width: 767px) {
  .about__titles {
    line-height: 0.8;
  }
}

.about__line__drawing {
  position: absolute;
  width: 1000rem;
  display: flex;
  overflow: hidden;
  transform: translateY(-160rem);
  z-index: 10;
}

.about__title__wrapper {
  height: 100vh;
  position: relative;
  display: inline;
  align-self: center;
  margin-left: 4rem;
  margin-right: 100rem;
}

.about__title__label {
  margin-top: 12rem;
  margin-left: 6rem;
}

.about__question__wrapper {
  position: absolute;
  margin-left: 6rem;
  white-space: nowrap;
  padding: 0;
  pointer-events: none;
  transform: translateX(-50%) rotate(-90deg);
  will-change: transform;
}

.about__title__murtaza__question {
  display: inline-block;
  text-align: left;
  visibility: visible !important;
  opacity: 1 !important;
  margin-left: 40rem;
}

.about__button__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  position: relative;
}

.about__button {
  width: 15rem;
  margin-bottom: 10rem;
  pointer-events: auto;
  transition: opacity 0.4s ease;
}
@media (max-width: 767px) {
  .about__button {
    position: absolute;
    right: 29rem;
  }
}
.about__button .about__button__icon {
  position: absolute;
  width: 15rem;
  display: block;
  justify-content: center;
  bottom: 7rem;
}
@media (max-width: 767px) {
  .about__button .about__button__icon {
    transform: translateY(6rem);
    bottom: 5rem;
    width: 18rem;
  }
}

.about__content__wrapper {
  margin-right: 30rem;
  display: inline-block;
  flex-direction: column;
  position: relative;
  top: 45%;
}

.about__content__line {
  margin-top: 5rem;
  border-top: 1px solid #ff0000;
  width: 150px;
  align-items: flex-start;
  display: flex;
}

.about__content__description_how {
  line-height: 1.3;
  margin-top: 5rem;
  width: 45rem;
}
.about__content__description_how li {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.about__content__media {
  position: absolute;
  bottom: 80%;
  margin-left: 40%;
  z-index: -1;
}

.about__gallery {
  height: 100vh;
  position: relative;
}

.about__gallery__wrapper {
  display: grid;
  grid-template-columns: 40rem 40rem 40rem 40rem;
  grid-template-rows: 30rem 30rem 30rem;
  justify-content: center;
  object-fit: contain;
}
@media (max-width: 767px) {
  .about__gallery__wrapper {
    display: grid;
    grid-template-columns: 25rem 25rem 25rem;
    grid-template-rows: 20rem 20rem 20rem 20rem;
  }
}

.about__gallery__media {
  top: 50%;
  left: 50%;
}

.about__gallery__media__image {
  max-height: 12rem;
  max-width: 12rem;
  object-fit: cover;
}
@media (max-width: 767px) {
  .about__gallery__media__image {
    max-height: 15rem;
    max-width: 15rem;
    object-fit: cover;
  }
}

.about__gallery__icon {
  position: absolute;
  width: 19rem;
  display: flex;
  top: 60%;
  right: 0;
}
@media (max-width: 767px) {
  .about__gallery__icon {
    right: 37rem;
    top: 65%;
  }
}

.about__highlight {
  display: flex;
  flex-direction: column;
  margin-bottom: 20%;
  justify-content: flex-end;
  text-align: center;
}
@media (max-width: 767px) {
  .about__highlight {
    margin-bottom: 70%;
  }
}

.about__highlight__wrapper {
  width: 40rem;
}

.about__what__wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}

.about__what__label {
  margin-right: 4rem;
}

.about__what__text {
  line-height: 1.3;
  width: 45rem;
}

.about__what__media {
  margin-left: 15rem;
  margin-right: 15rem;
}

.about__what__media__image {
  max-height: 80rem;
}

.detail__information__artwork:first-child {
  margin-top: 20rem;
}
.detail__information__artwork:last-child {
  margin-bottom: 4;
}

.detail__information__artwork__wrapper {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  left: 30%;
  justify-content: center;
}
@media (max-width: 767px) {
  .detail__information__artwork__wrapper {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    width: 100%;
  }
}
.detail__information__artwork--Right .detail__information__artwork__wrapper {
  flex-direction: row-reverse;
}

.detail__information__artwork__box {
  display: flex;
  width: 50rem;
}
@media (max-width: 767px) {
  .detail__information__artwork__box {
    display: flex;
    justify-content: center;
  }
}

.detail__information__artwork__title {
  width: 25rem;
  margin-left: 7rem;
  margin-right: 0;
}
@media (max-width: 767px) {
  .detail__information__artwork__title {
    margin-right: 5rem;
    margin-left: 0;
  }
}

.detail__information__artwork__description {
  width: 40rem;
  padding-bottom: 4rem;
}
.detail__information__artwork__description p:not(:first-child) {
  margin-top: 2.5rem;
}
@media (max-width: 767px) {
  .detail__information__artwork__description {
    width: 90%;
  }
}

.detail__information__artwork__media {
  position: relative;
  height: 50rem;
  margin: 4rem;
  width: 50rem;
}
.detail__information__artwork__media:first-child {
  margin-top: auto;
}
.detail__information__artwork__media:not(:first-child) {
  margin-top: 4rem;
}
.detail__information__artwork__media:last-child {
  margin-bottom: 4rem;
}

.detail__information__artwork__media__image {
  object-fit: contain;
}

.detail__information__gallery {
  height: 58.6rem;
  margin: 0;
  position: relative;
  pointer-events: none;
  visibility: hidden;
}

.detail__information__gallery__wrapper {
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.detail__information__gallery__media {
  height: 43.5rem;
  margin: 0 4rem;
  position: relative;
  width: 30.9rem;
  object-fit: cover;
}
@media (max-width: 767px) {
  .detail__information__gallery__media {
    justify-content: center;
    align-items: center;
    width: 32rem;
  }
}

.detail__information__gallery__media__image {
  object-fit: cover;
}

.detail {
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}

.detail__wrapper {
  width: 100%;
  display: block;
}
@media (max-width: 767px) {
  .detail__wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.detail__media {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
@media (max-width: 767px) {
  .detail__media {
    object-fit: contain;
    width: 100%;
    height: 70rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.detail__media__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  justify-content: center;
}

.detail__information__myth {
  position: fixed;
  top: 17rem;
  left: 3rem;
  text-align: border-bottom;
  transform: translateX(-20rem) translateY(27rem) rotate(-90deg);
  color: #ff0000;
}
@media (max-width: 767px) {
  .detail__information__myth {
    display: none;
  }
}

.detail__information__title {
  position: absolute;
  width: 50%;
  text-align: right;
  padding-right: 10rem;
  padding-top: 4rem;
}
@media (max-width: 767px) {
  .detail__information__title {
    position: relative;
    text-align: left;
    margin-left: 5rem;
  }
}

.detail__information__content {
  margin-left: 50%;
  padding-top: 3rem;
  transform: translateX(-1.5rem);
}
@media (max-width: 767px) {
  .detail__information__content {
    display: flex;
    flex-direction: column;
    margin-left: 10rem;
  }
}

.detail__information__highlights {
  margin-top: 4rem;
}

.detail__information__highlight {
  align-items: center;
  display: flex;
}
.detail__information__highlight:not(:first-child) {
  margin-top: 4rem;
}

.detail__information__highlight__icon {
  border: 1px solid #ff0000;
  border-radius: 50%;
  margin-right: 4rem;
  margin-left: 1.5rem;
  height: 4rem;
  width: 4rem;
}

.detail__information__list {
  margin-top: 5rem;
}

.detail__information__item {
  display: flex;
}
.detail__information__item:not(:first-child) {
  margin-top: 4rem;
}

.detail__information__item__title {
  width: 10rem;
  padding-right: 4rem;
}

.detail__information__item__description {
  max-width: 44rem;
  border-bottom: 1px solid currentColor;
  padding-bottom: 4rem;
}

.line {
  width: 112px;
  height: 47px;
  border-bottom: 1px solid currentColor;
  position: absolute;
}

.detail__information__link {
  display: inline-block;
  margin-top: 3rem;
  margin-bottom: 25rem;
}
@media (max-width: 767px) {
  .detail__information__link {
    margin-bottom: 10rem;
  }
}

.detail__button {
  position: absolute;
  right: 5rem;
  padding: 1rem;
  z-index: 10;
  margin-top: 18rem;
}
@media (max-width: 767px) {
  .detail__button {
    position: relative;
    margin-bottom: 4rem;
    margin-top: 10rem;
    right: 0;
  }
}

.detail__button__icon {
  position: absolute;
  width: 10rem;
  height: 10rem;
  transform: translateY(-40%) translateX(-80%);
}
@media (max-width: 767px) {
  .detail__button__icon {
    width: 18rem;
    height: 14rem;
    transform: translateY(-30%) translateX(-80%);
  }
}

.home {
  overflow: hidden;
  text-align: center;
  object-fit: cover;
}

.home__wrapper:before, .home__wrapper:after {
  content: "";
  height: 35rem;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.home__gallery {
  display: flex;
  flex-wrap: wrap;
  left: 50%;
  padding: 0 2.5rem;
  top: 0;
  width: 246.2rem;
  height: 164.8rem;
  visibility: hidden;
  object-fit: cover;
  overflow: visible;
}

.home__gallery__media:nth-child(1) {
  width: 31.4rem;
  height: 22.6rem;
  top: 22.2rem;
  left: 3.6rem;
}
.home__gallery__media:nth-child(2) {
  width: 62rem;
  height: 40.3rem;
  top: 13.3rem;
  left: 40.2rem;
}
.home__gallery__media:nth-child(3) {
  width: 32.3rem;
  height: 48.4rem;
  top: 0rem;
  left: 109.3rem;
}
.home__gallery__media:nth-child(4) {
  width: 30.6rem;
  height: 22rem;
  top: 14.7rem;
  left: 144.3rem;
}
.home__gallery__media:nth-child(5) {
  width: 66.2rem;
  height: 47.8rem;
  top: 0rem;
  left: 180.1rem;
}
.home__gallery__media:nth-child(6) {
  width: 65rem;
  height: 46.8rem;
  top: 64.6rem;
  left: 0rem;
}
.home__gallery__media:nth-child(7) {
  width: 65.7rem;
  height: 36.8rem;
  top: 59.1rem;
  left: 68.2rem;
}
.home__gallery__media:nth-child(8) {
  width: 58.7rem;
  height: 42.3rem;
  top: 53.6rem;
  left: 139.1rem;
}
.home__gallery__media:nth-child(9) {
  width: 42.8rem;
  height: 57rem;
  top: 53.6rem;
  left: 200.6rem;
}
.home__gallery__media:nth-child(10) {
  width: 41.9rem;
  height: 31.1rem;
  top: 126.2rem;
  left: 1.5rem;
}
.home__gallery__media:nth-child(11) {
  width: 42.9rem;
  height: 30.9rem;
  top: 116.9rem;
  left: 47.8rem;
}
.home__gallery__media:nth-child(12) {
  width: 42rem;
  height: 62rem;
  top: 101.4rem;
  left: 95.1rem;
}
.home__gallery__media:nth-child(13) {
  width: 22.9rem;
  height: 46rem;
  top: 101.4rem;
  left: 145.5rem;
}
.home__gallery__media:nth-child(14) {
  width: 71.3rem;
  height: 45.4rem;
  top: 116.9rem;
  left: 174.9rem;
}

.home__gallery__media__image {
  display: block;
  opacity: 1;
}

.home__button {
  bottom: 5rem;
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
  left: 50%;
  align-items: center;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .home__button {
    bottom: 10rem;
    width: 40rem;
  }
}
.home__button .home__button__icon {
  display: block;
  bottom: -2.5rem;
  width: 27rem;
  position: absolute;
  margin-left: -6rem;
}
@media (max-width: 767px) {
  .home__button .home__button__icon {
    bottom: -2.5rem;
    width: 40rem;
  }
}

.journal__wrapper {
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: block;
}

.journal__title {
  align-items: margin-right;
  justify-content: right;
  text-align: right;
  padding-top: 40rem;
  padding-right: 5rem;
  border-bottom: 1px solid #ff0000;
}

.journal__posts {
  display: flex;
  flex-direction: column;
  margin-left: 25%;
}
@media (max-width: 767px) {
  .journal__posts {
    margin-left: 5rem;
  }
}

.journal__posts__wrapper {
  position: relative;
  margin-top: 5rem;
  display: inline-block;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 50rem;
  margin-right: 5rem;
}

.journal__posts__row {
  float: left;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #ff0000;
}
.journal__posts__row:first-child {
  border-top: 1px solid #ff0000;
  margin-top: 2rem;
}

.journal__posts__date {
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: inline-block;
  vertical-align: middle;
}

.journal__posts__link {
  position: absolute;
  text-align: left;
  margin-left: 25%;
  padding: 1rem;
}

.myths__wrapper {
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.myths__titles {
  margin-left: 5rem;
  margin-top: 10%;
  margin-right: 5rem;
  position: absolute;
  width: -webkit-fill-available;
}

.myths__titles__label {
  margin-top: 5rem;
  height: 2rem;
}

.myths__titles__title {
  height: 20rem;
  border-bottom: 1px solid #ff0000;
}
.myths__titles__title span {
  transform: translateY(4rem);
}

.myths__gallery__link {
  pointer-events: cursor;
  display: flex;
  flex-direction: column;
}
.myths__gallery__link:last-child {
  margin-bottom: 10rem;
}

@media (max-width: 767px) {
  .myths__gallery__media {
    margin-bottom: 5rem;
  }
}

.myths__gallery__media__image {
  height: 27rem;
  top: 0;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  filter: grayscale(1) contrast(0.95) url(#gamma-red);
  transition: 0.3s ease;
}
.myths__gallery__media__image:hover {
  opacity: 1;
  filter: none;
}

.myths__content {
  height: 100vh;
  width: 100vw;
}

.post__wrapper {
  text-align: left;
  width: 100%;
  height: 100%;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 15rem;
}

.post__titles {
  display: flex;
  flex-direction: column;
  margin-left: 20%;
}
@media (max-width: 767px) {
  .post__titles {
    margin-top: 10rem;
    margin-left: 5rem;
  }
}

.post__titles__title {
  padding-top: 5%;
  margin-bottom: 4rem;
  position: absolute;
}

.post__titles__title2 {
  color: #e6dfd7;
  padding-top: 8%;
  width: 100%;
  margin-bottom: 10rem;
  display: flex;
  justify-content: left;
  position: relative;
  white-space: nowrap;
  word-wrap: normal;
  overflow: visible;
}

.post__titles__media {
  margin: 3rem;
  justify-content: center;
}

.post__titles__media__image {
  width: 35%;
  padding-top: 4rem;
}
@media (max-width: 767px) {
  .post__titles__media__image {
    width: 80%;
  }
}

.post__title__wrapper {
  display: flex;
  white-space: nowrap;
  word-wrap: normal;
  will-change: transform;
  margin-top: 10%;
  margin-bottom: 4rem;
  line-height: 1;
  padding: 0;
}
.post__title__wrapper:first-child {
  margin-top: 15rem;
}

.post__title {
  display: inline-block;
  margin-left: 20rem;
  visibility: visible !important;
  opacity: 1 !important;
}

.post__header__media {
  align-items: center;
  justify-content: margin-left;
  left: 50%;
  margin: 2rem;
  display: flex;
  flex-direction: column;
}

.post__header__media__image {
  width: 70rem;
  max-height: 50rem;
  justify-content: center;
  object-fit: contain;
}

.post__header__media__caption {
  text-align: left;
  margin-top: 1rem;
  text-transform: uppercase;
}

.post__text__wrapper {
  margin-top: 8rem;
  margin-left: 25%;
  width: 40%;
}
@media (max-width: 767px) {
  .post__text__wrapper {
    margin-left: 5rem;
    margin-right: 5rem;
    width: 80%;
  }
}

.post__text__text {
  line-height: 1.5;
}
.post__text__text p {
  text-decoration: underline;
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
}
.post__text__text li {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-left: 4rem;
}
.post__text__text ol {
  margin-left: 4rem;
}

.post__link__wrapper {
  margin-left: 25%;
  margin-top: 2rem;
}