.post{
  @extend %page;


}

.post__wrapper{
  text-align: left;
  width: 100%;
  height: 100%;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  padding-bottom: 15rem;
//  background: $color-paper;
}

.post__titles{
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  @include media('<phone') {
    margin-top: 10rem;
    margin-left: 5rem;
  }
}

.post__titles__wrapper{
  //position: relative;

}

.post__titles__title{
  @extend %description-35-bold-slanted;
  padding-top: 5%;

  margin-bottom: 4rem;
  position: absolute;
}

.post__titles__title2{

  @extend %title-200;
  @extend %cover;
  color: $color-emboss;
  padding-top: 8%;
  width: 100%;
  margin-bottom: 10rem;
  display: flex;
  justify-content: left;
  position: relative;
  white-space: nowrap;
  word-wrap: normal;
  overflow: visible;
}

//header image
.post__titles__media{

}
.post__titles__media{
  margin: 3rem;
  justify-content: center;
}
.post__titles__media__image{
  width: 35%;
  padding-top: 4rem;
  @include media('<phone') {
    width: 80%;

  }
}
.post__titles__date{

}

//slicetype-title

.post__title__wrapper {
  display: flex;
  white-space: nowrap;
  word-wrap: normal;
  will-change: transform;
  margin-top: 10%;
  margin-bottom: 4rem;
//  justify-content: center;
  line-height: 1;
  padding: 0;
  &:first-child {

    margin-top:15rem;

  }
}
.post__title {

  @extend %title-160;

//  align-items: center;
  //justify-content: margin-left;
  display:inline-block;

  margin-left: 20rem;
  visibility: visible !important;
  opacity: 1 !important;
}


//if section.slice_type == 'image_with_caption'
.post__header{

}
.post__header__wrapper{

}
.post__header__media{
  align-items: center;
  justify-content: margin-left;
  left: 50%;
  margin: 2rem;
  display: flex;
  flex-direction: column;

}
.post__header__media__image{
  width: 70rem;
  max-height: 50rem;

  justify-content: center;
  object-fit: contain;
}
.post__header__media__caption{
  text-align: left;
  margin-top: 1rem;
  text-transform: uppercase;
}

//if section.slice_type == 'text'
.post__text{


}
.post__text__wrapper{
  margin-top: 8rem;
  margin-left: 25%;
  width: 40%;
  @include media('<phone') {

    margin-left: 5rem;
    margin-right: 5rem;
    width: 80%;
  }
}
.post__text__text{
  line-height: 1.5;

  // display: list-item;
  // list-style-type: disc ;
  // list-style-position: inside;
  p {
    @extend %description-16-bold;
    text-decoration: underline;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }

  li {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 4rem;

  }
  ol {
    margin-left: 4rem;
  }
  // a &:link:hover {
  //   text-decoration: underline;
  // }


}

//if section.slice_type == 'link'
.post__link{

}
.post__link__wrapper{
  margin-left: 25%;
  margin-top: 2rem;
}
.post__information__link{
  @extend %description-12-bold-slanted

}
